import * as molecules from "./molecules";

const monthSelector = {
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  SelectDate: "select date, the current date selected is {{month}} {{year}}",
  SelectDateYear: "select date, the current date selected is {{year}}",
  SelectAllDates: "select date",
  previousYear: "previous year",
  nextYear: "next year",
  previousMonth: "previous month",
  nextMonth: "next month",
  AllDates: "All dates",
  Clear: "Clear",
} as const;

const common = {
  clear: "Clear",
} as const;

const attachment = {
  removeAttachment: "Remove attachment: {{name}}",
  Remove: "Remove",
  addAttachment: "Add",
  attachmentName: "Attachment: {{name}}",
  showMore: "Show more",
  showLess: "Show less",
} as const;

const searchBar = {
  ClearSearch: "Clear search",
  Search: "Search",
  SearchAriaLabel: "Search input",
} as const;

const dropdown = {
  GoBack: "Go back",
  MoreOptions: "More options",
} as const;

const a11yMenu = {
  AccessibleNavigation: "Accessible navigation",
  SkipToMainContent: "Skip to main content",
} as const;

const accordion = {
  trigger: "Accordion trigger",
};

const showMoreAccordion = {
  show: "Show",
  read: "Read",
  more: "more",
  less: "less",
};

const address = {
  shipping: "Shipping address",
  billing: "Billing address",
} as const;

const avatar = {
  avatarFor: "Avatar for {{- firstName}} {{- lastName}}",
  wellevateLabel: "Created in Wellevate",
  archived: "Archived",
  blocked: "Blocked",
};

const breadcrumb = {
  collapsedBreadcrumb: "Collapsed breadcrumb",
} as const;

const countrySelector = {
  dropdownLabel: "Country selector dropdown for phone number",
  defaultCountrySelected: "country code selected",
} as const;

const bottomSheet = {
  clear: "Clear",
  closeButtonLabel: "Close drawer",
  previousMenuLabel: "Back to previous menu",
  filters: "Filters",
  showSelection: "Show selection",
} as const;

const toast = {
  CloseNotification: "Close notification",
  success: "Success",
  error: "Error",
} as const;

const modal = {
  CloseModal: "Close modal",
} as const;

const button = {
  Loading: "Loading",
};

const pagination = {
  nextPage: "Next page",
  previousPage: "Previous page",
} as const;

const message = {
  dismiss: "Dismiss",
} as const;

const quantitySelector = {
  itemQuantityWidget: "Item quantity widget",
  specifyItemQuantity: "Specify the item quantity",
  buttonIncrement: "Increment item quantity by 1",
  buttonDecrement: "Decrement item quantity by 1",
};

const footer = {
  FullscriptCopyRights: "Fullscript {{currentYear}}. All rights reserved.",
  TermsOfService: "Terms of Service",
  PrivacyStatement: "Privacy Statement",
  AccessibilityPolicy: "Accessibility Policy",
  CaliforniaPrivacyNotice: "California Privacy Notice",
  HelpAndSupport: "Help and Support",
  ShippingPolicy: "Shipping Policy",
  RefundPolicy: "Refund Policy",
  FDAdisclaimerLine1:
    "*These statements have not been evaluated by the Food and Drug Administration. These products are not intended to diagnose, treat, cure, or prevent any disease.",
  FDAdisclaimerLine2:
    "†Claims based on traditional homeopathic practice, not accepted medical evidence. Not FDA evaluated.",
  ShippingDisclaimer:
    "Please note that all orders placed before 3:00 pm Eastern Standard Time are shipped out the same business day. Orders placed over the weekend or on holidays will be processed the next business day.",
  SecureCheckout: "Fullscript ensures all payment information is <1>stored securely</1>.",
  SecureCheckoutIcon: "Fullscript secure checkout",
  HIPAAalttext: "Health Insurance Portability and Accountability Act (HIPAA) Compliant",
};

const carousel = {
  next: "Next",
  previous: "Previous",
  start: "Start carousel autoplay",
  stop: "Stop carousel autoplay",
  viewNextSlide: "View next slide",
  slideSelected: "Slide selected",
};

const pageSearch = {
  ariaFilters: "View filters",
};

const bulkActionCheckbox = {
  selectAll: "Select all",
  AllItemsSelected: "<0>All</0> {{itemName}} selected",
  selectionCount: "<0>{{selectedCount}}</0> of <2>{{totalCount}}</2> {{itemName}} selected",
  item: "item",
  item_plural: "items",
};

const tag = {
  remove: "Remove {{text}}",
};

const richTextEditor = {
  richTextArea: "Rich textarea",
  bold: "Bold",
  italic: "Italic",
  unorderedList: "Unordered list",
  orderedList: "Ordered list",
  heading: "Heading",
  link: "Link",
  highlightedLink: "Highlighted link",
  apply: "Apply",
  placeHolderText: "Add a message",
  error: "Rich text error",
  fsAssistGenerateMessage: "Generate message",
} as const;

const avatarMenu = {
  label: "Avatar for {{name}}",
  signOut: "Sign out",
};

const sidebarNav = {
  expandSidebar: "Expand sidebar",
  fullscriptLogo: "Fullscript logo",
  hideSidebar: "Hide sidebar",
  profileAndAccountSettings: "Profile and account settings",
  toggleSidebar: "Toggle sidebar",
  openNav: "Open navigation",
  returnHome: "Return home",
};

const themeToggle = {
  darkMode: "Dark Mode",
  lightMode: "Light Mode",
};

const phoneInput = {
  tooShort: "Phone number must have at least 8 digits",
};

const aviary = {
  ...molecules,
  a11yMenu,
  accordion,
  address,
  attachment,
  avatar,
  avatarMenu,
  bottomSheet,
  breadcrumb,
  bulkActionCheckbox,
  button,
  carousel,
  common,
  countrySelector,
  dropdown,
  footer,
  message,
  modal,
  monthSelector,
  pageSearch,
  pagination,
  quantitySelector,
  richTextEditor,
  searchBar,
  sidebarNav,
  showMoreAccordion,
  tag,
  themeToggle,
  toast,
  phoneInput,
};

export { aviary };
