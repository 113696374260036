export const sharedProductSwapping = {
  availability: {
    title: "Availability",
    outofstock: {
      details: {
        estimatedInStock:
          "Estimated in stock in between {{expectedReturnMonth}}-{{additionalReturnMonth}}",
        noReturnDate: "Restock date pending. Check back soon or contact support.",
      },
    },
    discontinued: {
      details: "Unavailable",
      description:
        "This product is no longer available, and is currently in {{atRiskPlansTotalCount}} active {{planTerm}}. Swap it across all affected plans now.",
    },
    toBeDiscontinued: {
      details: {
        inAFewWeeks: "To be discontinued in a few weeks",
        inTheComingMonths: "To be discontinued in the coming months",
      },
      description:
        "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}. Quickly swap it across all affected plans using the Product substitution tool.",
    },
    backordered: {
      title: "Delayed",
      details: "Delayed – this item may take longer to ship",
      description:
        "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}. Quickly provide substitutes across all affected plans using the Product substitution tool.",
    },
    defaultDescription:
      "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}.",
    wholesaleDescription:
      "You've ordered this product in the past. Consider swapping this for another similar product. ",
  },
  OriginalProductSwapped: "Original product was swapped.",
  ViewOriginalProduct: "View original product",
  OriginalProduct: "Original product",
  dosageInstructions: "Dosage instructions",
  NoDosageInstructions: "No dosage instructions",
  Close: "Close",
  Size: "Size",
  plan: "plan",
  plan_plural: "plans",
  cancel: "Cancel",

  leaveBeforeModal: {
    title: "Your changes will not be saved",
    description: "By leaving you'll lose any progress you’ve started.",
    leave: "Leave",
  },

  drawer: {
    choice: {
      swap: {
        title: "Swap product",
        description:
          "Swap the discontinued product to ensure your {{patientTermPlural}} don't experience delays.",
      },
      substitute: {
        create: {
          title: "Add substitutes",
          description:
            "Provide product substitutions to ensure your {{patientTermPlural}} don't experience delays.",
        },
        update: {
          title: "Edit substitutes",
          description:
            "Edit substitute products to ensure your {{patientTermPlural}} don't experience delays.",
        },
        selectUpToThree: "Select up to 3:",
        addedSuccess: "Substitute product added",
      },
      similarProductsDescription:
        "The suggestions are based on the most similar products available",
      chooseAnotherProduct: "choose another product.",
      yourSwap: "Your swap:",
      yourSubstitutes: "Your substitutes:",
      suggestedProducts: "Suggested products",
      emptyState: {
        title: "Unavailable product suggestions",
        description:
          "There are currently no available product suggestions. Add your own products by searching the catalog or choose your favorite products.",
        searchCatalog: "Search the catalog",
        favorites: "Favorites",
      },
    },
  },

  swapMode: {
    selectSwap: "Select swap",
    addSubstitute: "Add substitute",
    originalProduct: "Original product",
    selectedProduct: "{{numberOfSelected}}/{{total}} product selected",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    selectedProduct_plural: "{{numberOfSelected}}/{{total}} products selected",
    swapProducts: "Swap products",
    substituteProducts: "Substitute products",
  },
} as const;
