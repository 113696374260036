export const dosage = {
  takeWith: "Take with",
  takeWithInstruction: "Take with {{ takeWith }}",
  takeWithPill: "With {{ takeWith }}",
  notSelected: "Not selected",
  food: "Food",
  water: "Water",
  emptyStomach: "Empty stomach",
  dosageNonNumeric: "Dosage must be numeric",
  serving: " serving",
  serving_plural: " servings",
  additionalNotes: "Additional notes",

  // Frequency
  frequency: "Frequency",
  oncePerDay: "Once per day",
  twicePerDay: "Twice per day",
  threeTimesPerDay: "Three times per day",
  fourTimesPerDay: "Four times per day",
  fiveTimesPerDay: "Five times per day",
  asNeeded: "As needed",

  // Time of day
  anytime: "Anytime",
  uponWaking: "Upon waking",
  morning: "Morning",
  afternoon: "Afternoon",
  evening: "Evening",
  bedtime: "Bedtime",
} as const;
