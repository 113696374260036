/* eslint-disable @typescript-eslint/naming-convention */

export const sharedCommon = {
  close: "Close",
  downloadOnAppStore: "Download on the App Store",
  nowOnIos: "Now on iOS",
  Labs: "Labs",
  // Impersonation Terms
  Impersonating: "Impersonating \xA0",
  StopImpersonatingTitle: "Stop impersonating",
  stopImpersonating: "Stop impersonating and go back to admin",
  operatingAs:
    "You are operating as <1>{{- userFullName}}'s</1> profile. <3>$t(stopImpersonating)</3>",
  InvalidDOB: "Invalid date of birth",
  AddToCart: "Add to cart",
  SelectADifferentSize: "Select a different size",
  AddedToCart: "Added to cart",
  ViewCart: "View Cart",
  QuantityAdded: "{{quantity}} added!",
  OutOfStock: "Out of stock",
  PerUnit: "/ Unit",

  ProductOutOfStockOrDiscontinued:
    "This product cannot be purchased or prescribed because it is out of stock or discontinued",

  Sku: "SKU: {{sku}}",
  dosage: "Dosage",

  practitionerTerm: "practitioner",
  practitionerTermPlural: "practitioners",

  USA: "USA",
  CAN: "CA",
  UnitedStates: "United States",
  Canada: "Canada",
  Back: "Back",
  Next: "Next",
  Skip: "Skip",
  SkipForNow: "Skip for now",
  Done: "Done",
  Submit: "Submit",
  BackToCatalogHome: "Back to catalog home",

  EmptySimilarProducts: "It looks like there are no <1>similar</1><2/><3>products</3> available",
  Description: "Description",

  SizeOfVariant: "<0>Size:</0> {{variantDescriptor}}",

  // Duration
  DurationOptional: "Not selected",
  Duration: "Duration",
  UntilSymptomsResolve: "Until symptoms resolve",
  Ongoing: "Ongoing",
  For1Week: "For 1 week",
  For2Weeks: "For 2 weeks",
  For3Weeks: "For 3 weeks",
  For1Month: "For 1 month",
  For2Months: "For 2 months",
  For3Months: "For 3 months",
  For6Months: "For 6 months",

  // Frequency
  OncePerDay: "Once / day",
  TwicePerDay: "Twice / day",
  ThreeTimesPerDay: "Three times / day",
  FourTimesPerDay: "Four times / day",
  EveryMorning: "Every morning",
  EveryNight: "Every night",
  OnceADay: "once per day",
  PerDay: "per day",
  AsNeeded: "As needed",

  All: "All",
  brands: "brands",
  Brands: "Brands",
  Brand: "Brand",
  Popular: "Popular",
  Filters: "Filters",
  CloseFilters: "Close filters",
  RemoveIngredient: "Remove ingredient",
  SearchIngredients: "Search ingredients...",
  NoResults: "No results to show...",
  Ingredient: "Ingredient",
  Ingredients: "Ingredients",
  SubTypesOf: "Subtypes of {{- ingredient}}",
  ShowAll: "Show all",
  SearchProducts: "Search for products...",
  SearchAll: "Search for products, brands, ingredients, and more...",
  servings: "Serving(s)",
  Condition: "Condition",
  SupplementType: "Supplement type",
  SearchFor: "Search for products named ",
  LoadMoreFilters: "Load more {{title}}",
  ShowMore: "Show more",
  ShowLess: "Show less",
  ShowMoreEllipsis: "... Show more",
  ShowLessEllipsis: "... Show less",
  Remove: "Remove",
  RemoveAll: "Remove all",
  RemoveItem: "Remove {{- item}}",
  Compare: "Compare",
  CompareProducts: "Compare products",
  Edit: "Edit",
  Save: "Save",
  Result: "{{count}} result",
  Result_plural: "{{count}} results",
  CatalogSearchTitleShowing: "Showing",
  CatalogSearchTitleOutOf: "out of",
  CatalogSearchTitleResult: "result",
  CatalogSearchTitleResultFor: "result for",
  CatalogSearchTitleResults: "results",
  CatalogSearchTitleResultsFor: "results for",
  CatalogSearchTitleZero: "No results found",
  CatalogSearchTitleZeroWithQuery: "No results found for",
  LoadMore: "Load more",
  Sort: "Sort",
  Common: "Common",
  Confirm: "Confirm",
  Cancel: "Cancel",
  Manage: "Manage",
  Select: "Select",
  PreviouslyOrdered: "Previously ordered",
  PrimaryProduct: "Primary product",
  Featured: "Featured",
  NumOfProductsSelected: "{{count}}/4 products selected",
  OnSale: "On sale",
  TotalDiscount: "Total discount",
  Backordered: "Backordered",
  Discontinued: "Discontinued",
  Form: "Form",
  Allergens: "Allergens",
  Demographic: "Demographic",
  Certifications: "Certifications",
  More: "More",
  ShowAllAttachments: "ShowAllAttachments",
  LessThan: "Less than or equal",
  GreaterThan: "Greater than or equal",
  EqualTo: "Equal to",
  AnyValue: "Any value",
  Clear: "Clear",
  Clearall: "Clear all",
  ClearFilters: "Clear filters",
  ClearAllSearchFilters: "Clear all search filters",
  DidYouMeanThisEmail: "Did you mean <1>{{suggestedEmail}}</1>?",
  discountPill: "{{discount}}% off orders",
  SelectSuggestedEmail: "Select suggested email",
  EmailRequired: "Email is required",
  EmailMustHaveAt: `Email must include "@"`,
  EmailMustHaveDot: `Email must include "."`,
  EmailInvalidFormat: `Email is invalid format`,
  Email: "Email",
  EmailAddress: "Email address",
  totalDiscount: "{{discount}}% total discount applied to all products",
  cartDiscountBanner: "Your practitioner has applied a {{discount}}% discount to all products",
  promotionTotalDiscountBanner:
    "Total discount is now {{discount}}% on all products for a limited time",
  MyAccount: "My account",
  footerTerms:
    "This page is protected by reCAPTCHA, and subject to the <1>Google Privacy Policy</1> and <3>Google Terms of Service</3>",

  CreditCardEndingIn: "Credit card ending in {{lastFourDigits}}",
  cardNumber: "Card number",
  expiryDate: "Expiry date",
  expiryLabel: "EXP",
  Exp: "Exp. {{month}}/{{year}}",
  CVC: "CVC",
  mmYY: "MM/YY",
  holdOn: "Hold on!",
  exitWithoutSaving:
    "You are about to exit this page without saving. If you proceed all information you have provided up to this point will be lost. Are you sure you want to proceed?",
  finishAndSave: "Finish then save",
  leaveWithoutSaving: "Leave without saving",
  Loading: "Loading",
  Dismiss: "Dismiss",
  createdIn: "Created in {{sourcePlatform}}",
  orderedIn: "Ordered in {{sourcePlatform}}",
  PractitionerAvatar: "Avatar for Practitioner",
  userAvatar: "User avatar",
  AvatarFor: "Avatar for {{- firstName}} {{- lastName}}",
  takeTheTour: "Take the tour",
  beta: "BETA",
  Interpunct: "•",
  youSave: "You save",
  Savings: "Savings",
  Notifications: "Notifications",
  Step: "Step",
  PopularProducts: "Popular Products",
  viewProductDetails: "View product details",
  copyLink: "Copy link",
  linkCopied: "Link copied",

  // Delivery
  Estimated: "Estimated {{arrivalDate}}",
  EstimatedArrival: "Estimated arrival {{arrivalDate}}",
  DeliveryOptionAccessibleLabel: "Delivery Method: {{method}} - Cost: {{cost}}",
  BestOptionForHeatSensitive:
    "Best shipping option for <heatSensitive>heat-sensitive products</heatSensitive>",
  freeFirstUppercase: "Free",

  // Labs
  Sent: "Pending payment",
  Paid: "Paid",
  RequisitionReadySimple: "Requisition ready",
  ResultsIn: "Results in",
  Shipped: "Shipped",
  ChangesSaved: "Changes saved",

  // Checkout
  EnteredAddress: "Entered address",
  VerifiedAddress: "Verified address",

  restrictedAccess: "Restricted access",
  FailedToCopyText: "Failed to copy to clipboard",
  CopiedToClipboard: "Copied to clipboard",
  VideoError: "Your browser doesn't support HTML video.",
} as const;
